import axios from "axios";
import Config from "../config";

const updateChatNotification = id => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/notification/update-chat",
        data: { id }
    });
};

export default updateChatNotification;
