import axios from "axios";
import Config from "../config";

const getRoomUserAvailable = (roomId, text, limit) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/user-available",
        data: { roomID : roomId, limit: limit || 30, search: text || '' }
    });
};

export default getRoomUserAvailable;
