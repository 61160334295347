import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import React, {useRef, useState, useEffect} from 'react';
import "./ModalForward.css";
import Picture from "../../../components/Picture";
import getRoomAvailableForward from "../../../actions/getRoomAvailableForward";
import {useGlobal} from "reactn";
import MasterConstant from "../../../constants/MasterConstant";
import {FaCheck, GoQuote} from "react-icons/all";
import message from "../../../actions/message";
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import {useDispatch, useSelector} from "react-redux";
import createRoom from "../../../actions/createRoom";
import store from "../../../store";


const ModalForward = () => {
    const room = useSelector(state => state.io.room);
    const searchInput = useRef();
    const [loading, setLoading] = useState(false);
    const [showModalForward, setShowModalForward] = useGlobal('showModalForward');
    const [messageModeAction, setMessageModeAction] = useGlobal('messageModeAction');
    const [listSearchForward, setSearchForward] = useState({});
    const [messageForward, setMessageForward] = useGlobal('messageForward');
    const [searchTerm, setSearchTerm] = useState('');
    const [messageTerm, setMessageTerm] = useState('')
    const user = useGlobal('user')[0];

    useEffect(async () => {
        if(showModalForward){
            setSearchTerm([]);
            setMessageTerm([]);
            getRoomAvailableForward('', room._id).then(res => {

                let result = {
                    rooms : res.data.rooms,
                    users : res.data.users,
                    recent : res.data.recent
                };
                setSearchForward(result);
            }).catch(err => {
                console.log(err)
            });
        }

    }, [showModalForward])



    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(room){
                getRoomAvailableForward(searchTerm, room._id).then(res => {
                    let result = {
                        rooms : res.data.rooms,
                        users : res.data.users,
                        recent : res.data.recent
                    };
                    setSearchForward(result);
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                });
            }

        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const handleClose = async () => {
        setShowModalForward(false);
        setMessageModeAction('new');
        setMessageForward([]);
    }

    const handleSend = async (e, type, objId) => {
        e.preventDefault();

        let finalText = messageTerm;

        let room = objId;

        if(type === 'people'){
            const res = await createRoom(objId);
            room = res.data.room._id;
        }

        let messageData = {
            roomID: room,
            authorID: user.id,
            content: finalText,
            contentType: 'text',
            action : 'forward',
            replies : messageForward._id,
            forward : 1
        };

        // await setShowModal(false);
        // e.target.parentElement.documentElement('forward-btn-send').classList('hide');
        const element = e.target.parentNode;
        element.querySelector('.forward-btn-send').classList.add('hide')
        element.querySelector('.is_sent').classList.remove('hide')

        await message(messageData);

        getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));

    }


    const ListRecent = () => {
        return (
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Recent</Form.Label>
                <ListGroup as="ol" className="list_recent">
                    {listSearchForward.recent.map(myRecent => {
                        return (<ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            key={`list_recent_${myRecent._id}`}
                        >
                            <div>
                                <Picture group={myRecent.isGroup} picture={myRecent.picture} title={myRecent.title} />
                                <div className="ms-2 me-auto">
                                    {(myRecent.title).substr(0, 32)}{(myRecent.title).length > 32 && '...'}
                                </div>
                            </div>

                            <Button className="forward-btn-send" onClick={(e) => handleSend(e, 'recent',myRecent._id)}>
                                Send
                            </Button>

                            <Button className="forward-btn-send is_sent hide" type="button"> <FaCheck/> Sent</Button>

                        </ListGroup.Item>);
                    })}
                </ListGroup>
            </Form.Group>
        );
    }

    const ListRooms = () => {
        return (<Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Groups</Form.Label>
            <ListGroup as="ol" className="list_rooms">
                {listSearchForward.rooms.map(myRoom => {
                    return (<ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                        key={`list_rooms_${myRoom._id}`}
                    >
                        <div>
                            <Picture group={myRoom.isGroup} picture={myRoom.picture} title={myRoom.title} />
                            <div className="ms-2 me-auto">
                                {(myRoom.title).substr(0, 32)}{(myRoom.title).length > 32 && '...'}
                            </div>
                        </div>

                        <Button className="forward-btn-send" onClick={(e) => handleSend(e, 'groups',myRoom._id)}>
                            Send
                        </Button>

                        <Button className="forward-btn-send is_sent hide" type="button"> <FaCheck/> Sent</Button>
                    </ListGroup.Item>);
                })}
            </ListGroup>
        </Form.Group>);
    }

    const ListUsers = () => {
        return (<Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Peoples</Form.Label>
            <ListGroup as="ol" className="list_users">
                {listSearchForward.users.map(myUser => {
                    const title = `${myUser.firstName} ${myUser.lastName}`.substr(0, 22);
                    return (<ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                        key={`list_users_${myUser._id}`}
                    >
                        <div>
                            <Picture user={myUser} />
                            <div className="ms-2 me-auto">
                                {title}
                            </div>
                        </div>

                        <Button className="forward-btn-send" onClick={(e) => handleSend(e, 'people',myUser._id)}>
                            Send
                        </Button>

                        <Button className="forward-btn-send is_sent hide" type="button"> <FaCheck/> Sent</Button>
                    </ListGroup.Item>);
                })}
            </ListGroup>
        </Form.Group>);
    }

    const ContentForward = () => {
        return MasterConstant.contentReply(messageForward);
    };

    return (
        <Modal
            id="modal-forward"
            show={showModalForward}
            onHide={handleClose} animation={false}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Forward Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="group-create">
                    <div className="forward-content">
                        <Form.Group controlId="formBasicContent">
                            {/*<Form.Label>Forward message from <strong>{messageForward.author.firstName} {messageForward.author.lastName}</strong></Form.Label>*/}
                            <div className="forward_message">
                                <GoQuote/> {<ContentForward/>}
                            </div>
                        </Form.Group>

                        <Form.Group controlId="forward_input">
                            <Form.Control type="text" placeholder="Type a message here" onChange={(e) => setMessageTerm(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="forward-hr"></div>

                    <div className="forward-list">
                        <div className="search-bar uk-flex uk-flex-center uk-flex-middle">
                            <div className="icon"  onClick={() => searchInput.current.focus()}>
                                <FiSearch/>
                            </div>
                            <div className="uk-inline search">
                                <input className="uk-input uk-border-pill" placeholder="Search" ref={searchInput} onChange={(e) => setSearchTerm(e.target.value)}/>
                            </div>
                        </div>
                        <div className="search-list">
                            {listSearchForward.recent && (<ListRecent />)}
                            {listSearchForward.rooms && (<ListRooms />)}
                            {listSearchForward.users && (<ListUsers />)}
                        </div>


                    </div>
                    <div className="forward-hr"></div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Done</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalForward;
