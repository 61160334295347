import React, {useState, useRef, useEffect} from 'react';
import Message from "./Message";
import { Lightbox } from "react-modal-image";
import Config from "../../../config";
import {useGlobal} from "reactn";
import './Messages.sass';
import {useDispatch, useSelector} from "react-redux";
import getMoreMessages from "../../../actions/getMoreMessages";
import Actions from "../../../constants/Actions";
import Picture from "../../../components/Picture";
import store from "../../../store";

const Messages = () => {
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const messages = useSelector(state => state.io.messages) || [];
    const room = useSelector(state => state.io.room);
    const [loading, setLoading] = useState(false);
    const typing = useSelector(state => state.messages.typing)

    const chat = useRef(null);

    const [open, setOpen] = useState(null);

    useEffect(() => {
        if (chat.current) chat.current.scrollTop = chat.current.scrollHeight;
    }, [messages.length]);

    useEffect(() => {
        if (typing && chat.current) chat.current.scrollTop = chat.current.scrollHeight;
    }, [typing])

    let other = {
        firstName: 'A', lastName: 'A'
    };

    if (!room.isGroup && room.people) {
        room.people.forEach(person => {
            if (person._id !== user.id) other = person;
        });
    }

    if ( typing !== false && room && room.isGroup && room.people) {
        room.people.forEach(person => {
            if (person._id === typing?.id) other = person;
        });
    }

    const Messages = messages.map((message, index) => {
        let messageData = [];

        if(room.messageSeen){
            room.messageSeen.forEach((messageSeen, index)=>{
                if(messageSeen.message === message._id){
                    messageData.push(messageSeen);
                }
            });
        }
        return <Message
            key={message._id}
            message={message}
            previous={messages[index - 1]}
            next={messages[index + 1]}
            onOpen={setOpen}
            messageSeen={messageData}
        />;
    });

    const onScroll = () => {
        if (chat.current.scrollTop === 0) {
            if (loading) return;
            setLoading(true);
            getMoreMessages({roomID: room._id, firstMessageID: messages[0]._id}).then(res => {
                store.dispatch({type: Actions.MORE_MESSAGES, messages: res.data.messages});
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });
        }
    };






    return (
        <div className="messages-wrapper messages-wrapper-fix" ref={chat} onScroll={onScroll}>
            <div className="messages-container">

                {open && (
                    <Lightbox
                        medium={`${Config.url || ''}/api/images/${open.content}/1024/${brand}`}
                        large={`${Config.url || ''}/api/images/${open.content}/2048/${brand}`}
                        alt="Lightbox"
                        hideDownload={true}
                        onClose={() => setOpen(null)}
                    />
                )}
                {Messages}
                {typing?.isTyping && <div className="message left attach-previous">
                    <div className="picture">
                        <Picture user={other} />
                    </div>
                    <div className="content-x">
                        <div className="bubble bubble-left left" style={{width: 50}}>
                            <div id="wave">
                                <span className="dot"/>
                                <span className="dot"/>
                                <span className="dot"/>
                            </div>
                        </div>
                        <div className={`message-details left`} style={{color: "transparent"}}>-</div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Messages;
