import React, {useEffect, useRef, useState} from 'react';
import getRoomUserAvailable from "../../../actions/getRoomUserAvailable";
import {Button, Form, Modal, Table} from "react-bootstrap";
import User from "./User";
import {useGlobal} from "reactn";
import updateGroup from "../../../actions/updateGroup";
import {useHistory} from "react-router-dom";
import './AddToGroup.sass';
import Actions from "../../../constants/Actions";
import getRoom from "../../../actions/getRoom";
import {useDispatch, useSelector} from "react-redux";
import {FiSearch} from "react-icons/fi";

const AddToGroup = (room) => {
    const searchInput = useRef();
    const user = useGlobal('user')[0];
    const searchText = useGlobal('searchATG')[0];
    const [newGroupUsers, setNewGroupUsers] = useGlobal('newGroupUsersATG');
    const [searchResults, setSearchResults] = useGlobal('searchResultsATG');
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useGlobal('showModalAddToGroup');

    useEffect(() => {
        // getRoomUserAvailable(room.room._id).then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
        setNewGroupUsers([]);
    }, [setSearchResults]);

    const create = async e => {
        e.preventDefault();
        const res = await updateGroup({type: 'add-people', roomId: room.room._id, data: newGroupUsers});
        await setShowModal(false);

    }

    const handleShowMember = async () => {
        await getRoomUserAvailable(room.room._id).then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
        await setShowModal(true);
    }

    const onSelect = id => {
        if (newGroupUsers.includes(id)) {
            setNewGroupUsers(newGroupUsers.filter(u => u !== id));
        }
        else {
            setError(false);
            setNewGroupUsers([...newGroupUsers, id])
        }
    };

    const searchResultsList = searchResults.map(user => (
            <User key={user._id} user={user} selected={newGroupUsers.includes(user._id)} onSelect={() => onSelect(user._id)} />
        )
    );

    const handleCloseMember = () => {
        setNewGroupUsers([]);
        setShowModal(false);
    }

    const onChange = e => {
        // if (nav !== 'searchATG') setNav('searchATG');
        // setSearch(e.target.value);
        getRoomUserAvailable(room.room._id, e.target.value).then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
    };

    return (
        <div className="wrapper-add-to-group">
            <div className="member add-to-group" onClick={handleShowMember}>
                <div className={`status`}>
                </div>
                <div className="img">+</div>
                <div className="text">Add User Group</div>
            </div>
            <Modal
                id="modal-add-to-group"
                show={showModal}
                onHide={handleCloseMember} animation={false}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Member Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="group-create">
                        <div className="search-bar uk-flex uk-flex-center uk-flex-middle">
                            <div className="icon"  onClick={() => searchInput.current.focus()}>
                                <FiSearch/>
                            </div>
                            <div className="uk-inline search">
                                <input className="uk-input uk-border-pill" placeholder="Search" ref={searchInput} onChange={onChange}/>
                            </div>
                        </div>
                        <button className="uk-margin-remove uk-button uk-button-large uk-button-primary create-button" onClick={create}>Select Users</button>
                        <div className="selection-text error" hidden={newGroupUsers.length <= 0 || !error}>
                            You must select some people!
                        </div>
                        <div className="selection-text" hidden={newGroupUsers.length <= 0}>
                            {newGroupUsers.length} selected - <a onClick={() => setNewGroupUsers([user.id])}>Clear</a>
                        </div>
                        <div className="rooms">
                            {searchResultsList}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMember}>Cancel</Button>
                    <Button onClick={create}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default AddToGroup;
