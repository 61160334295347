import axios from "axios";
import Config from "../config";

const getRoomAvailableForward = (text, room, limit) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/available-forward",
        data: {limit: limit || 30, search: text || '', room }
    });
};

export default getRoomAvailableForward;
