import React, {useRef, useState, useEffect, useCallback} from 'react';
import "./BottomBar.sass";
import "./BottomBar.css";
import {
    FiSend,
    FiImage,
    FiSmile,
    FiPaperclip, FiMinimize2, FiMaximize2
} from "react-icons/fi";
import {
    AiOutlineCloseCircle,
    BiTask,
    BiX,
    FaUsers,
    IoIosCloseCircle,
    MdFormatQuote
} from "react-icons/all";
import {useGlobal} from "reactn";
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import message from "../../../actions/message";
import uploadImage from "../../../actions/uploadImage";
import uploadFile from "../../../actions/uploadFile";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import typing from "../../../actions/typing";

import MasterConstant from "../../../constants/MasterConstant";
import updateMessage from "../../../actions/updateMessage";
import { MentionsInput, Mention } from 'react-mentions'
import defaultStyleTagUser from './defaultStyleTagUser'
import defaultMentionStyle from './defaultMentionStyle'
import listenForOutsideClick from "../../../components/OutsideClicks";
import Config from '../../../config';
import {merge} from "../../../lib/utils";
import store from "../../../store";

const BottomBar = () => {
    const imageInput = useRef(null);
    const fileInput = useRef(null);
    const inputRef = useRef(null);

    const ref = useGlobal('ref')[0];
    const room = useSelector(state => state.io.room);
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];

    const [currentMessage, setCurrentMessage] = useGlobal('currentMessage');
    const [isPicker, showPicker] = useGlobal('isPicker');

    const [pictureRefs, addPictureRef] = useState([]);
    const [messageModeAction, setMessageModeAction] = useGlobal('messageModeAction');
    const [messageGetData, setMessageGetData] = useGlobal('messageGetData');
    const [reply, setReply] = useGlobal('reply');
    const [messageReply, setMessageReply] = useGlobal('messageReply');
    const [textArea, setTextarea] = useState();
    const [userTag, setUserTag] = useState();
    const dispatch = useDispatch();
    const [value, setValue] = useState('')
    const [isExpand, setExpand] = useGlobal('isExpand');

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [previewImages, setPreviewImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);

    useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));

    const handlePaste = async (e) => {
        let files = Array.from(e.clipboardData.files);

        // Lọc chỉ những file là ảnh
        let imageFilesFilter = files.filter(file => file.type.startsWith('image/') && !file.type.endsWith('icon'));

        if (imageFilesFilter.length === 0) {
            return;
        }

        setImageFiles(prevFiles => [...imageFilesFilter, ...prevFiles]);
    
        // Array to store the image promises
        let imagePromises = imageFilesFilter.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function(e) {
                    resolve(e.target.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
    
        // Wait for all promises to resolve
        try {
            let images = await Promise.all(imagePromises);
            setPreviewImages(prevImages => [...images, ...prevImages]);
        } catch (error) {
            console.error('Error reading files', error);
        }
    };

    useEffect(() => {
        const input = inputRef.current;

        if (input) {
            input.addEventListener('paste', handlePaste);
        }
    
        // Cleanup the event listener on component unmount
        return () => {
            if (input) {
                input.removeEventListener('paste', handlePaste);
            }
        };
    }, [imageFiles]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(value === '') {
                store.dispatch(typing(room, false));
            }
            else
            {
                store.dispatch(typing(room, true));
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)

    }, [value]);



    useEffect(() => {
        if(room){
            let userTag = [{
                id : 'all',
                display : 'all',
            }];
            room.people.map(user => {
                let dataUser = {
                    id : user._id,
                    display : user.firstName + ' ' + user.lastName,
                    lastName : user.lastName,
                    firstName : user.firstName,
                };
                if(user.picture){
                    dataUser.picture = user.picture.shieldedID;
                }
                userTag.push(dataUser);
                return userTag;
            })
            setUserTag(userTag);
        }

        setValue(currentMessage);

    }, [room, textArea, currentMessage])

    const sendMessage = async () => {
        if (value.length === 0 && imageFiles.length === 0) return;

        let finalText = value;
        let resMess;

        setValue('');
        setCurrentMessage('');
        if(messageModeAction !== 'edit'){

            // Gửi ảnh đính kèm nếu có
            if(imageFiles.length){
                await sendImagesPaste(imageFiles);
            }

            let messageData = { roomID: room._id, authorID: user.id, content: finalText, contentType: 'text', action : 'message'};
            if(reply){
                messageData.replies = messageReply;
                messageData.action = 'reply';
            }

            if(value.length > 0){
                resMess = await message(messageData);

                let newMessage = resMess.data.message;

                setReply(false);

                showPicker(false);
                setMessageModeAction('new');
                setMessageGetData([]);


                store.dispatch({type: Actions.MESSAGE, message: newMessage});
                getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
            }

        } else {
            let messageData = {
                type: 'edit', roomID: room._id, messageId: messageGetData._id, data : finalText, action: 'edit'
            };
            if(reply){
                messageData.replies = messageReply;
            }

            updateMessage(messageData);

            setReply(false);
            showPicker(false);
            setMessageModeAction('new');
            setMessageGetData([]);
        }
    };

    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            if (event.type === "keydown" && !event.shiftKey) {
                sendMessage();
                event.preventDefault();
            }
        }
    };

    const sendImagesPaste = async images => {
        setPreviewImages([]);
        setImageFiles([])
        
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const res = await uploadImage(image, ref + i);

            const resMess = await message({roomID: room._id, authorID: user.id, content: res.data.image.shieldedID, type: 'image', imageID: res.data.image._id});
            let newMessage = resMess.data.message;
            store.dispatch({type: Actions.MESSAGE, message: newMessage});
        }

        getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
    };

    const sendImages = async images => {
        let tmpRefs = [];
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            tmpRefs.push(ref + i);

            // const ReactS3Client = new S3(Config.awsS3);
            //
            // await ReactS3Client
            //     .uploadFile(image)
            //     .then(data => console.log(data))
            //     .catch(err => console.error(err))
            const res = await uploadImage(image, ref + i);

            const resMess = await message({roomID: room._id, authorID: user.id, content: res.data.image.shieldedID, type: 'image', imageID: res.data.image._id});
            let newMessage = resMess.data.message;
            store.dispatch({type: Actions.MESSAGE, message: newMessage});
        }
        addPictureRef([...pictureRefs, ...tmpRefs]);
        showPicker(false);
        getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
    };

    const sendFiles = async files => {
        for (let i = 0; i < files.length; i++) {
            if ((files[i].size / (1024 * 1024)) > 15) return alert('File exceeds 15MB limit!');
        }
        let tmpRefs = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            tmpRefs.push(ref + i);
            const res = await uploadFile(file, ref + i);

            const resMess = await message({roomID: room._id, authorID: user.id, content: res.data.file.shieldedID, type: 'file', fileID: res.data.file._id});
            let newMessage = resMess.data.message;
            store.dispatch({type: Actions.MESSAGE, message: newMessage});
        }
        addPictureRef([...pictureRefs, ...tmpRefs]);
        showPicker(false);
        getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
    };

    const closeReply = () => {
        setReply(false);
        setMessageReply(false);
        if(messageModeAction === 'reply') setMessageModeAction('new');
    }


    const ContentReply = () => {
        if(messageModeAction === 'edit' && messageGetData.replies){
            return MasterConstant.contentReply(messageGetData.replies);
        } else {
            return MasterConstant.contentReply(messageGetData);
        }
    };

    const ReplyMessage = () => {
        if(messageModeAction === 'edit' && !messageGetData.replies) {
            return (<div></div>);
        }
        else {
            return (
                <div className="answer_message_block">
                    <div className="block-content-message">
                        <div className="block-content-message-child position-relative">
                            <span><MdFormatQuote/> Replies to <strong>{messageGetData.author.firstName} {messageGetData.author.lastName}</strong></span>
                            <span><ContentReply/></span>
                            <span className="close_comma" onClick={closeReply}>
                            <IoIosCloseCircle/>
                        </span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const cancelEdit = () => {
        setMessageModeAction('new');
        setMessageGetData([]);
        setCurrentMessage('');
        setReply(false);
    }



    const onChange = useCallback((_, newValue) => setValue(newValue), [setValue])
    // const onChange = (_, value) => {
    //     setValue(value);
    // }
    const onAdd = useCallback((...args) => console.log(...args), [])

    let style = merge({}, defaultStyleTagUser, {
        input: {
            overflow: 'auto',
            height: 70,
        },
        highlighter: {
            boxSizing: 'border-box',
            overflow: 'hidden',
            height: 70,
        },
    })

    const Picture = ({user}) => {
        if(user.id !== 'all'){
            console.log(user)
            if (user.picture)
                return <img className="img" src={`${Config.url || ''}/api/images/${user.picture}/256/${brand}`} alt="Picture chat" />;
            else
                return <div className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
        } else {
            return <div className="img"><FaUsers/></div>;

        }

    };

    const addTask = () => {
        window.parent.postMessage({
            'func': 'showAddManagerWork',
            'message': ''
        }, "*");
    }

    const handleExpand = () => {
        setExpand(!isExpand).then();
    }

    const removePreviewImage = (index) => {
        const newItems = previewImages.filter((item, i) => i !== index);
        setPreviewImages(newItems);

        const newItemFiles = imageFiles.filter((item, i) => i !== index);
        setImageFiles(newItemFiles)
    }

    return (
        <div className={`bottom-container ${isExpand ? '' : 'bottom-basic'}`}>
            <div className="bottom-bar-container">
                {(previewImages && previewImages.length) ? <div id="image-review">
                    {previewImages.map((image, index) => (
                        <div key={index} className='img-review-item' style={{ backgroundImage: `url('${image}')` }}>
                            <div className="remove-icon" onClick={() => removePreviewImage(index)}><BiX/></div>
                        </div>    
                    ))}
                    
                </div> : <></>}
                <div className="bottom-bar-tool">
                    <div
                        ref={menuRef}
                        className={isOpen ? "m-menu -active" : "m-menu "}
                    >
                        { isOpen && (
                            <>
                                <div
                                    className="picker"
                                    hidden={!isOpen}
                                >
                                    <Picker
                                        onSelect={emoji => setValue(value + emoji.native)}
                                        darkMode={false}
                                        title="Emoji"
                                        native={true}
                                        set="facebook"
                                    />
                                </div>
                                
                            </>
                        ) }
                        <div id="emojis-btn" className="button smile"
                            // onClick={() => showPicker(!isPicker)}
                            onClick={toggle}
                        ><FiSmile/>
                        </div>
                    </div>


                    {messageModeAction === 'new' && (
                        <input
                            className="file-input"
                            type="file"
                            ref={imageInput}
                            accept="image/*"
                            multiple={true}
                            onChange={e => sendImages(e.target.files)}
                        />
                    )}

                    {messageModeAction === 'new' && (
                        <div className="button image-attach" onClick={() => imageInput && imageInput.current && imageInput.current.click()}>
                            <FiImage/>
                        </div>
                    )}

                    {messageModeAction === 'new' && (
                        <input
                            className="file-input"
                            type="file"
                            ref={fileInput}
                            multiple={true}
                            onChange={e => sendFiles(e.target.files)}
                        />
                    )}

                    {messageModeAction === 'new' && (
                        <div className="button attach" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                            <FiPaperclip/>
                        </div>
                    )}

                    <div className="button add-task" onClick={() => addTask()}><BiTask/></div>
                </div>
                <div className="bottom-bar-tool-extra">
                    {isExpand ? (<div className="button add-task" onClick={() => handleExpand()}><FiMinimize2/></div>) :
                        (<div className="button add-task" onClick={() => handleExpand()}><FiMaximize2/></div>)}
                </div>
            </div>

            {reply && (<ReplyMessage/>)}
            <div className="bottom-bar-conversation uk-flex uk-flex-middle">
                <MentionsInput
                    className="message-input"
                    onKeyPress={handleKeyPress}
                    onKeyDown={handleKeyPress}
                    value={value}
                    data-emoji-input="unicode"
                    inputRef={inputRef}
                    // inputRef={c => (setTextarea(c))}
                    onChange={onChange}
                    style={style}
                    placeholder={"Type a message"}
                    a11ySuggestionsListLabel={"Suggested mentions"}
                    rows={1}
                >
                    <Mention
                        markup="@__display__-__id__@"
                        displayTransform={(id, display) => `@${display}`}
                        trigger="@"
                        data={userTag}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => {
                            // console.log(suggestion)
                            return (<div><Picture user={suggestion} /><div className="user 12312123">{highlightedDisplay}</div></div>);
                        }}
                        onAdd={onAdd}
                        style={defaultMentionStyle}
                    />
                </MentionsInput>

                {messageModeAction === 'edit' && (<div className="button" onClick={cancelEdit}><AiOutlineCloseCircle/></div>)}
                <div className="button" onClick={sendMessage}><FiSend/></div>
            </div>
        </div>
    );
}

export default BottomBar;
