
export default {
    demo: false,
    brand: 'Epoints', // Footer brand
    urlBrand: 'https://epoints.vn/',
    appName: 'Matthewsliquor', // App Name
    showCredits: true, // Show credits in login page
    url: 'https://chat.matthewsliquor.com.au',
    port: 4000
};

