import axios from "axios";
import Config from "../config";

const getNotificationUser = () => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/notification/user",
        data: {}
    });
};

export default getNotificationUser;
