import axios from 'axios';
import { Cookies } from "react-cookie";

const setAuthToken = token => {
    const cookies = new Cookies();
    let brand = cookies.get('BRAND');

    axios.defaults.headers.common['brand-code'] = brand;

    if (token) {
        // Apply to every request
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else {
        // Delete auth header
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthToken;
