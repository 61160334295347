import React, {useEffect, useState} from 'react';
import Config from "../../../config";
import moment from "moment";
import "./Notification.css";
import updateNotification from "../../../actions/updateNotification";
import getNotification from "../../../actions/getNotification";
import {useGlobal} from "reactn";
import {useHistory, useLocation} from "react-router-dom";

const Notification = ({notification}) => {

    const [read, setRead] = useState(notification.isRead);
    const [notifications, setNotifications] = useGlobal('notification');
    const brand = useGlobal('brand')[0];
    const history = useHistory();
    const location = useLocation();

    const Picture = () => {
        let firstName = notification.createdBy.firstName || "User";
        let lastName = notification.createdBy.lastName || "Name";
        if (notification.createdBy.picture)
            return(<img src={`${Config.url || ''}/api/images/${notification.createdBy.picture.shieldedID}/256/${brand}`} alt="Picture" className="picture"/>)
        else
            return <div className="img">{firstName.substr(0,1).toUpperCase()}{lastName.substr(0,1).toUpperCase()}</div>
    }


    let createdAt = notification.createdAt;
    const date = moment(createdAt).format('MMM D h:mm A');

    const handleClick = async (e) => {
        if(!notification.isRead){
            await updateNotification({type : "read", notiId: notification._id});
        }
        const target = `/room/${notification.room._id}`;
        if (location.pathname !== target) history.replace(target);
    }
    return (
        <div className="notification_list" onClick={handleClick}>
            <div className="room false uk-flex  ">
                <div className="uk-flex uk-flex-middle">
                    <div className="profile">
                        <Picture/>
                    </div>
                </div>
                <div className={`text ${!notification.isRead && 'notification-not-read'}`}>
                    <div className="message" dangerouslySetInnerHTML={{__html: notification.content}}/>
                    <div className="controls">
                        <div className="date">{date}  { notification.room.isGroup && (" |   " + notification.room.title) }</div>
                    </div>
                </div>

                <div className="controls" hidden="">
                    <div className="uk-inline">

                        <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar" className="uk-dropdown">
                            <div className="link">Remove</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Notification;
