import jwtDecode from "jwt-decode";
import IO from "socket.io-client";
import Config from "../config";
import Actions from "../constants/Actions";
import store from "../store";
import getRooms from "./getRooms";
import messageSound from "../assets/message.mp3";
import {setGlobal} from "reactn";
import { useLocation } from 'react-router-dom'
import getRoom from "./getRoom";
import getNotification from "./getNotification";
import getNotificationUser from "./getNotificationUser";
import updateChatNotification from "./updateChatNotification";
import { Cookies } from "react-cookie";
const socketPromise = require('../lib/socket.io-promise').promise;

const initIO = token => dispatch => {
    const user = jwtDecode(token);

    const cookies = new Cookies();

    let io = IO((Config.url || '') + '/', {
        query: {
            brand: cookies.get('BRAND')
        }
    });
    io.request = socketPromise(io);

    io.on('connect', () => {
        io.emit('authenticate', {token});
        console.log('IO connected');
    });

    io.on('authenticated', () => {
        console.log('IO authenticated');
        store.dispatch({type: Actions.IO_INIT, io});
    });

    io.on('message-in', async data => {

        console.log('message-in')
        const {room, message} = data;

        const currentRoom = store.getState().io.room;
        const currentUser = store.getState().io.user;

        const audio = document.createElement('audio');
        audio.style.display = "none";
        audio.src = messageSound;
        audio.autoplay = true;
        audio.onended = () => audio.remove();
        document.body.appendChild(audio);

        if (!currentRoom || currentRoom._id !== room._id) {
            store.dispatch({type: Actions.MESSAGES_ADD_ROOM_UNREAD, roomID: room._id});
        }

        if (!currentRoom) return;
        if (currentRoom._id === room._id){
            store.dispatch({type: Actions.MESSAGE, message})

            await updateChatNotification(currentRoom._id).then().catch(err => console.log(err));

        };

        await getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));

        await getNotificationUser().then(res => store.dispatch({type: Actions.SET_USER_NOTIFICATION, notificationsUser: res.data})).catch(err => console.log(err));

        window.parent.postMessage({
            'func': 'reloadCountNoti',
        }, "*");
    })

    io.on('room-in', data => {
        getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
    });

    io.on('room-out', data => {
        console.log('room-out', data);
        const dataRoom = data.room;
        const remover = data.remover;
        const currentRoom = store.getState().io.room;

        // neu dang o trong room thi reload lai
        console.log(currentRoom._id ,dataRoom._id ,remover,user.id);
        if(currentRoom._id === dataRoom._id && remover === user.id){
            window.location.reload();
        } else {
            getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
        }
    });

    io.on('room-change', data => {
        console.log('room-change', data)
        const currentRoom = store.getState().io.room;
        const dataRoom = data.room;

        getRooms().then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));

        // neu dang o trong room thi moi load lai
        if(currentRoom._id === dataRoom._id){
            const resRoom = data.room;
            getRoom(resRoom._id).then( res => {
                store.dispatch({type: Actions.SET_ROOM, room: res.data.room});
                store.dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
                store.dispatch({type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: res.data.room._id});
            }).catch(err => {
                store.dispatch({type: Actions.SET_ROOM, room: null});
                store.dispatch({type: Actions.SET_MESSAGES, messages: []});
            });
        }
    });

    io.on('notification-in', async data =>{
        console.log('notification-in', data);
        await getNotification().then(res => store.dispatch({type: Actions.NOTIFICATION, notifications: res.data.notifications})).catch(err => console.log(err));
        await getNotificationUser().then(res => store.dispatch({type: Actions.SET_USER_NOTIFICATION, notificationsUser: res.data})).catch(err => console.log(err));

    });

    io.on('newProducer', data => {
        console.log('newProducer', data)
        if (data.socketID !== io.id) store.dispatch({type: Actions.RTC_PRODUCER, data})
    });

    io.on('leave', data => {
        console.log('leave', data);
        let producers = store.getState().rtc.producers;
        producers = producers.filter(producer => producer.socketID !== data.socketID);
        console.log('producers after leave', producers);
        store.dispatch({type: Actions.RTC_RESET_PRODUCERS, producers, socketID: data.socketID})
    });

    io.on('consumers', data => {
        console.log('consumers', data);
        store.dispatch({type: Actions.RTC_CONSUMERS, consumers: data});
    });

    io.on('newPeer', data => {
        console.log('newPeer', data);
        store.dispatch({type: Actions.RTC_NEW_PEER, data});
    });

    io.on('call', data => {
        console.log('call', data);
        store.dispatch({type: Actions.RTC_SET_COUNTERPART, counterpart: data.counterpart});
        store.dispatch({type: Actions.RTC_CALL, data});
    });

    io.on('close', data => {
        console.log('close', data);
        store.dispatch({type: Actions.RTC_CLOSE, data});
    });

    io.on('answer', data => {
        console.log('answer', data);
        store.dispatch({type: Actions.RTC_ANSWER, data});
    });

    io.on('remove', data => {
        console.log('remove', data.producerID);
        let producers = store.getState().rtc.producers;
        producers = producers.filter(producer => producer.producerID !== data.producerID);
        console.log('producers after remove', producers);
        store.dispatch({type: Actions.RTC_RESET_PRODUCERS, producers, socketID: data.socketID, lastLeaveType: 'remove', producerID: data.producerID})
    });

    io.on('onlineUsers', data => {
        store.dispatch({type: Actions.ONLINE_USERS, data});
    });

    io.on('refresh-meetings', data => {
        store.dispatch({type: Actions.REFRESH_MEETINGS, timestamp: data.timestamp});
    });

    io.on('user-deleted', async () => {
        localStorage.removeItem('token');
        await setGlobal({
            token: null,
            user: {},
        });
    })

    io.on('typing', data => {
        if (store.getState().io.room && data.roomID === store.getState().io.room._id) {
            if (data.isTyping) {
                clearTimeout(window.typingTimeout)
                window.typingTimeout = setTimeout(() => {
                    store.dispatch({type: Actions.SET_TYPING, typing: false});
                }, 10000);
            }
            else {
                clearTimeout(window.typingTimeout);
            }
            store.dispatch({type: Actions.SET_TYPING, typing: data});
        }
    })

    io.on('disconnected', () => {

    });

    window.onbeforeunload = function() {
        io.emit('leave', {socketID: io.id, roomID: store.getState().rtc.roomID});
        return;
    }
};

export default initIO;
