import React, {useEffect, useState} from 'react';
import moment from "moment";
import './Message.sass';
import './Message.css';
import emojiRegex from 'emoji-regex/text';
import Config from "../../../config";
import {useGlobal} from "reactn";
import ReactImageAppear from 'react-image-appear';
import {FiDownloadCloud} from "react-icons/fi";
import striptags from "striptags";
import { saveAs } from 'file-saver';

import {
    AiOutlineEdit,
    AiOutlinePushpin, BiTask,
    GrEdit,
    MdFormatQuote, RiShareForwardFill, TiArrowLoop
} from "react-icons/all";
import updateGroup from "../../../actions/updateGroup";
import {useDispatch, useSelector} from "react-redux";
import MasterConstant from "../../../constants/MasterConstant";
import updateMessage from "../../../actions/updateMessage";
import ReactTooltip from "react-tooltip";
import Actions from "../../../constants/Actions";
import createRoom from "../../../actions/createRoom";
import {useHistory, useLocation} from "react-router-dom";
import store from "../../../store";


/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setOpenMenuAction) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target.closest(".menu-action"))) {
                if(ref.current.className === 'menu-action'){
                    setOpenMenuAction(false);
                }
            }
        }


        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const Message = ({message, previous, next, onOpen, messageSeen}) => {

    const [nav, setNav] = useGlobal('nav');
    const brand = useGlobal('brand')[0];
    const room = useSelector(state => state.io.room);
    let { author, content, date } = message;
    const setPanel = useGlobal('showReply');
    const [messageModeAction, setMessageModeAction] = useGlobal('messageModeAction');
    const [messageGetData, setMessageGetData] = useGlobal('messageGetData');
    const [openMenuAction, setOpenMenuAction] = useState(true);
    const [currentMessage, setCurrentMessage] = useGlobal('currentMessage');
    const [reply, setReply] = useGlobal('reply');
    const history = useHistory();
    const location = useLocation();
    const [messageReply, setMessageReply] = useGlobal('messageReply');

    const [showModalForward, setShowModalForward] = useGlobal('showModalForward');
    const [messageForward, setMessageForward] = useGlobal('messageForward');

    const user = useGlobal('user')[0];
    const totalMessageSeen = messageSeen.length - 1;

    if (!author) author = { firstName: 'Deleted', lastName: 'User' };
    if (previous && !previous.author) previous.author = { firstName: 'Deleted', lastName: 'User' };
    if (next && !next.author) next.author = { firstName: 'Deleted', lastName: 'User' };

    const isMine = user.id === author._id;

    let attachPrevious = false, attachNext = false;

    if (previous && Math.abs(moment(previous.date).diff(moment(date), 'minutes')) < 3  && author._id === previous.author._id)
        attachPrevious = true;
    if (next && Math.abs(moment(next.date).diff(moment(date), 'minutes')) < 3 && author._id === next.author._id)
        attachNext = true;

    const Picture = ({user}) => {
        if (user.picture)
            return <img onClick={startChat} className="img" src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256/${brand}`} alt="Picture chat" />;
        else
            return <div onClick={startChat} className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
    };

    const Details = ({side}) => {
        if (!attachNext) return (
            <div className={`message-details ${side}`}>
                {moment(date).format('MMM DD - h:mm A')}
            </div>
        );
        else return null;
    };

    const PictureOrSpacer = () => {
        if (attachPrevious) return <div className="spacer"/>;
        else return (
            <div className="picture">
                <Picture user={author} />
            </div>
        );
    };

    const noEmoji = content.replace(emojiRegex(), '');
    const isOnlyEmoji = !noEmoji.replace(/[\s\n]/gm, '');

    const getBubble = () => {
        if (attachPrevious || isOnlyEmoji)
            if (isMine) return ' right';
            else return ' left';
        if (isMine) return ' bubble-right right';
        else return ' bubble-left left';
    };

    const downloadImage = (image_url,image_name) => {
        saveAs(image_url, image_name) // Put your image url here.
    }
    

    const Content = ({dataContent}) => {

        if(!dataContent){
            dataContent = message;
        }
        switch (dataContent.type) {
            case 'image':
                return (
                    <div className="content-image">
                        <a className="btn-download-img" onClick={() => downloadImage(`${Config.url || ''}/api/images/${dataContent.content}/0/${brand}`, dataContent.image.name)} download="custom-filename.jpg">
                            <div className="content-icon"><FiDownloadCloud/></div>
                        </a>

                        <ReactImageAppear
                            src={`${Config.url || ''}/api/images/${dataContent.content}/512/${brand}`}
                            animationDuration="0.2s"
                            onClick={() => onOpen(dataContent)}
                        />
                    </div>

                );
            case 'file':
                return (
                    <a href={`${Config.url || ''}/api/files/${dataContent.content}`} download={dataContent.file ? dataContent.file.name : 'File'}>
                        <div className="content-download">
                            <div className="content-file">
                                <div className="content-name">{dataContent.file ? dataContent.file.name : 'File'}</div>
                                <div className="content-size">{dataContent.file ? Math.round(dataContent.file.size / 1024 / 1024 * 10 ) / 10 + ' MB' : 'Size'}</div>
                            </div>
                            <div className="content-icon"><FiDownloadCloud/></div>
                        </div>
                    </a>
                );
            default:
                let finalContent = dataContent.content;
                if(finalContent){
                    let tagFullName;
                    let tagUser = finalContent.match(/@((?!@).)*-((?!@).)*@/gm);
                    if(tagUser){
                        tagUser.map(item => {

                            let tag = item.replace(/@/g, '');
                            let objTag = tag.split('-');
                            tagFullName = objTag[0];
                            finalContent = finalContent.replace(item, '<strong className="tagUser">@' + tagFullName + '</strong> ');
                        });
                    }


                    return(
                        <div dangerouslySetInnerHTML={{__html: MasterConstant.convertUrls(striptags(MasterConstant.nl2br(finalContent), [ 'a', 'strong', 'b', 'i', 'em', 'u', 'br' ]))}} />
                    );
                }
                return(
                    <div />
                );
        }
    };

    const getBubbleClass = () => {
        if (message.type === 'image') return 'bubble-image';
        return 'bubble';
    };

    const Replies = () => {
        const replies = message.replies;
        return(
            <div className="reply_message">
                <div className="block_reply_message">
                    <div><strong>{replies?.author?.firstName} {replies?.author?.lastName}</strong></div>
                    <span><Content dataContent={replies}  /></span>
                </div>
            </div>
        );
    }

    const onclickPinMessage = async () => {
        setOpenMenuAction(false);
        await updateGroup({
            type: 'single-data', roomId: room._id, data: message._id, field : 'pinMessage'
        });
    }

    const addTask = () => {
        let tagFullName;
        let finalContent = content;
        let tagUser = finalContent.match(/@((?!@).)*-((?!@).)*@/gm);
        if(tagUser){
            tagUser.map(item => {

                let tag = item.replace(/@/g, '');
                let objTag = tag.split('-');
                tagFullName = objTag[0];
                finalContent = finalContent.replace(item, '@' + tagFullName);
            });
        }
        window.parent.postMessage({
            'func': 'showAddManagerWork',
            'message': finalContent
        }, "*");
    }

    const onclickEditMessage = async () => {
        setOpenMenuAction(false);
        setMessageModeAction('edit');
        setMessageGetData(message);
        if(message.replies){
            setMessageReply(message.replies._id);
        }
        setCurrentMessage(message.content);
        if(message.replies){
            setReply(true);
        }
    }

    const onclickReply = () => {
        setMessageModeAction('reply');
        setMessageGetData(message);
        setMessageReply(message._id);
        setReply(true);
        setCurrentMessage('');
        setOpenMenuAction(false);
    }

    const onclickForward = () => {
        setShowModalForward('true');
        setMessageForward(message);
    }

    const onclickRecallMessage = async () => {
        setOpenMenuAction(false);
        await updateMessage({
            type: 'recall', roomId: room._id, messageId: message._id, data : message.content
        });
    }

    const handlerReaction = async (reaction) => {
        let data = {
            reactor : user.id,
            reaction : reaction
        };

        const res = await updateMessage({
            type: 'reaction', roomId: room._id, messageId: message._id, data : data
        });

        store.dispatch({type: Actions.UPDATE_MESSAGE, message: res.message});
    }

    const startChat = async () => {

        if(isMine) return;
        const res = await createRoom(author._id);
        setNav('rooms');
        const target = `/room/${res.data.room._id}`;
        if (location.pathname !== target) history.replace(target);
        store.dispatch({type: Actions.SET_ROOM, room: res.data.room});
        store.dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});

    }

    const ReactionMessage = (emoji) => {

        return(
            <div>
                {message.reaction_total > 0 && (<div className={`list-emoji-check ${isMine ?'list-emoji-right' : 'list-emoji-left'}`}>
                        <span
                            data-tip data-for={`reaction_people_${message._id}`}
                            className={`emoji-message cursor-pointer`}>
                            {isMine && (<span className="number_emoji number_emoji_right">{message.reaction_total}</span>)}
                            {message.reaction_like.length > 0 && (<span className='emoji_detail'>👍</span>)}
                            {message.reaction_heart.length > 0 && (<span className='emoji_detail'>❤️</span>)}
                            {message.reaction_haha.length > 0 && (<span className='emoji_detail'>😆</span>)}
                            {message.reaction_amazing.length > 0 && (<span className='emoji_detail'>😲</span>)}
                            {message.reaction_like.length > 0 && (<span className='emoji_detail'>😭</span>)}
                            {message.reaction_like.length > 0 && (<span className='emoji_detail'>😡</span>)}
                            {!isMine && (<span className="number_emoji number_emoji_left">{message.reaction_total}</span>)}
                            <ReactTooltip id={`reaction_people_${message._id}`} type='error'>
                                {message.reaction_people.map(reaction_people=>{
                                    return(<div key={`reaction_people_${message._id}_${reaction_people._id}`}>{reaction_people.firstName} {reaction_people.lastName}</div>);
                                })}
                            </ReactTooltip>
                        </span>
                </div>)}
            </div>

        );
    }

    const MessageAction = () => {
        return(
            <ReactTooltip clickable={true} place="right" backgroundColor='none' id={`message_action_${message._id}`} className='extraClass' delayHide={200} effect='solid'>
                <div>
                    {/*<div*/}
                    {/*    className="reaction-message"*/}
                    {/*>*/}
                    {/*    <span*/}
                    {/*        data-tip data-for={`reaction_emoji_${message._id}`}*/}
                    {/*        className={`hover_emoji  ${isMine ? 'hover_emoji_right' : 'hover_emoji_left'}`}>👍</span>*/}

                    {/*    <ReactTooltip backgroundColor='none' id={`reaction_emoji_${message._id}`} className='extraClass' delayHide={500} effect='solid'>*/}
                    {/*        <div*/}
                    {/*             className={isMine ? 'popup_emoji_right' : 'popup_emoji_left'}>*/}
                    {/*            <span onClick={() => {handlerReaction('reaction_like')}}>👍</span>*/}
                    {/*            <span onClick={() => {handlerReaction('reaction_heart')}}>❤️</span>*/}
                    {/*            <span onClick={() => {handlerReaction('reaction_haha')}}>😆</span>*/}
                    {/*            <span onClick={() => {handlerReaction('reaction_amazing')}}>😲</span>*/}
                    {/*            <span onClick={() => {handlerReaction('reaction_cry')}}>😭</span>*/}
                    {/*            <span onClick={() => {handlerReaction('reaction_angry')}}>😡</span>*/}
                    {/*        </div>*/}
                    {/*    </ReactTooltip>*/}
                    {/*</div>*/}
                    <div  className={`action_message action_message_show ${isMine ? 'action_message_right' : 'action_message_left' }`}>

                        <div className="menu-action">
                            {/*<span*/}
                            {/*    // onClick={() => setOpenMenuAction(true)}*/}
                            {/*>*/}
                            {/*    <BsThreeDots/>*/}
                            {/*</span>*/}
                            {(<div className="message-action-plus action_three_dot">
                                { (message.type !== 'image' && message.type !== 'file') && (
                                    <span onClick={addTask}>
                                        <BiTask/> Create Task
                                    </span>
                                )}

                                <span onClick={onclickPinMessage}>
                                    <AiOutlinePushpin/> Pin
                                </span>
                                {isMine && typeof message.type == 'undefined' && (
                                    <span onClick={onclickEditMessage}>
                                        <AiOutlineEdit/> Edit
                                    </span>
                                )}
                                {isMine && (
                                    <span onClick={onclickRecallMessage}>
                                        <TiArrowLoop/> Recall
                                    </span>
                                )}
                                <hr></hr>
                                <div className="action-group">
                                    <span className="action_quick" onClick={onclickReply}>
                                        <MdFormatQuote />
                                    </span>
                                    <span className="action_quick" onClick={onclickForward}>
                                        <RiShareForwardFill />
                                    </span>
                                </div>

                            </div> )}
                        </div>
                    </div>
                </div>
            </ReactTooltip>
        );
    }

    const MessageSeen = () => {
        return(
          <div className="avatar_view message">
              <div className="picture">
                  <div className="text_seen_by">seen by </div>
                  { messageSeen.slice(0,3).map(messageS => {
                      if(message.author && messageS.author){
                          if( message.author._id !== messageS.author._id ) {
                              return <Picture user={messageS.author} key={`MessageSeen_Pic_${message._id}_${messageS.author._id}`} />
                          }
                          else
                          {
                              return null;
                          }
                      } else {
                          return null;
                      }

                  })}
                  { totalMessageSeen > 3 ?? (
                      <span className="number_view">{totalMessageSeen - 3}</span>
                  )}
              </div>
          </div>
        )
    }

    return (
        <div>
            <div
                className={`message${isMine ? ' right' : ' left'}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>

                <PictureOrSpacer/>
                <div className={`content-x${isMine ? ' right' : ''}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>
                    {!attachPrevious && (<span className={isMine ? 'name_user_right' : 'name_user_left'}>{author.firstName} {author.lastName}</span>)}
                    <div
                        data-tip data-for={`message_action_${message._id}`}
                        className={`${getBubbleClass()}${getBubble()}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>

                        {message.replies && (<Replies/>)}
                        <Content/>
                        {message.edit !== 0 && (
                            <span
                                className={`hover_edit  ${isMine ? 'hover_edit_right' : 'hover_edit_left'}`}><GrEdit/>
                            </span>
                        )}
                        {/*<ReactionMessage/>*/}

                        {!message.recall && (<MessageAction />)}
                    </div>
                    <Details side={isMine ? 'right' : 'left'} />

                </div>
            </div>
            {totalMessageSeen > 0 && <MessageSeen key={`MessageSeen_${message._id}`}/>}
        </div>

    );
};

export default Message;
