import React, {useState, useEffect, useRef} from "react";
import './Room.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FiCircle, FiLogOut, FiMinus} from "react-icons/all";
import Config from '../../../config';
import {useGlobal} from "reactn";
import {Lightbox} from "react-modal-image";
import Picture from "../../../components/Picture";
import AddToGroup from "./AddToGroup";
import {FiEdit, FiX} from "react-icons/fi";
import {Button, Form, Modal, Row, Table} from "react-bootstrap";
import updateGroup from "../../../actions/updateGroup";
import {useHistory} from "react-router-dom";
import getRoom from "../../../actions/getRoom";
import Actions from "../../../constants/Actions";
import {useDispatch, useSelector} from "react-redux";
import getRooms from "../../../actions/getRooms";
import store from "../../../store";

const Room = () => {
    const room = useSelector(state => state.io.room);
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const roomID = useSelector(state => state.io.room._id);
    const imagesNumber = useSelector(state => state.io.room.images.length);
    const firstMessageID = useSelector(state => state.io.room.images.length > 0 ? state.io.room.images[state.io.room.images.length - 1]._id : null);
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const scrollContainer = useRef(null);
    //
    const [scrollHeight, setScrollHeight] = useState(0);
    const [open, setOpen] = useState(null);
    const [showModalRemovePeople, setShowModalRemovePeople] = useState(null);
    const [userRemove, setUserRemove] = useState(null);
    const [showModalLeaveGroup, setShowModalLeaveGroup] = useState(null);
    const [showModalUpdateTitle, setShowModalUpdateTitle] = useGlobal('showModalUpdateTitle');
    const [viewMembers, setViewMembers] = useState(false);
    const [errorRenameGroup, setErrorRenameGroup] = useState(false);

    //
    useEffect(() => {
        // if (scrollContainer.current.scrollTop === 0) scrollContainer.current.scrollTop = scrollHeight;
    }, [onlineUsers]);

    let other = {
        firstName: 'A', lastName: 'A'
    };

    if (!room.isGroup && room.people) {
        room.people.forEach(person => {
            if (person._id !== user.id) other = person;
        });
    }

    const Picture = ({picture, user, group}) => {
        if (picture)
            return <img src={`${Config.url || ''}/api/images/${picture.shieldedID}/256/${brand}`} alt="Picture" />;
        else
            return <div className="img">{ group ? room.title.substr(0, 1) : `${user.firstName.substr(0,1)}${user.lastName.substr(0,1)}`}</div>;
    };

    const rows = [];
    let rowIndex = 0;
    let row = [];

    room.images.forEach(message => {
        row.push(message);
        if (row.length === 2) {
            rows.push(row);
            rowIndex++;
            row = [];
        }
    });
    if (row.length > 0) rows.push(row);

    const images = rows.map((row, index) => {
        const images = row.map((message, index1) => {
            return <img key={`img-${index1}`} src={`${Config.url || ''}/api/images/${message.content}/256/${brand}`} onClick={() => setOpen(message)} />;
        });
        return <div key={`div-img-${index}`} className="row">{images}</div>
    });

    const onScroll = () => {
        setScrollHeight(scrollContainer.current.scrollHeight);
        if (scrollContainer.current.scrollTop >= (scrollContainer.current.scrollHeight - scrollContainer.current.offsetHeight)) {
            // Request more images
        }
    };

    const Notice = () => {
        if (images.length === 0) return (
            <div className="notice-text">There are no images in this conversation yet.</div>
        );
        return null;
    };

    const compare = (a, b) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0;
    };

    const people = room.people;

    people.sort(compare);

    const getColor = (id) => {
        if (onlineUsers.filter(u => u.id === id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === id && u.status === 'away').length > 0) return 'away';
        return 'offline';
    };
    let members = people.map(person => (
        <div className="member" key={`member_${person._id}`}>
            <div className={`status ${getColor(person._id)}`}>
                <FiCircle/>
            </div>
            <Picture picture={person.picture} user={person} />
            <div className="text">{person.firstName} {person.lastName}</div>
            {
                person._id === user.id && room.isGroup ? (
                    <div className="remove-member" onClick={() => {setShowModalLeaveGroup(true); setUserRemove(person._id)}}>
                        <FiLogOut/>
                    </div>
                ) : (

                    user.id === room.owner && (
                        <div className="remove-member" onClick={() => {setShowModalRemovePeople(true); setUserRemove(person._id)}}>
                            <FiX/>
                        </div>
                    )

                )
            }
        </div>
    ));

    const onSubmitRemovePeople = async e => {
        e.preventDefault();
        const res = await updateGroup({type: 'remove-people', roomId: room._id, data: userRemove});
        setShowModalRemovePeople(false);
    }

    const RemovePeople = () => (
        <Modal
            show={showModalRemovePeople}
            onHide={() => setShowModalRemovePeople(false)} animation={false}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Warning
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="group-create">
                    Remove this member from the group
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModalRemovePeople(false)}>Cancel</Button>
                <Button onClick={onSubmitRemovePeople}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )

    const LeaveGroup = () => (
        <Modal
            show={showModalLeaveGroup}
            onHide={() => setShowModalLeaveGroup(false)} animation={false}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Warning : Leave the group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="group-create">
                    Leaving the group will delete all messages
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModalLeaveGroup(false)}>Cancel</Button>
                <Button onClick={onSubmitRemovePeople}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )

    const handleSubmitUpdateTitle = async e => {
        e.preventDefault();
        const {title} = e.target.elements;
        if(title.value){
            const res = await updateGroup({type: 'title', roomId: room._id, data: title.value});
            setShowModalUpdateTitle(false);
        }
    }

    // const ModalUpdateTitle = () => (
    //
    // )

    const title = (room.isGroup ? room.title : `${other.firstName} ${other.lastName}`).substr(0, 22);

    return (
        <div className="details-room">
            <RemovePeople/>
            <Modal
                show={showModalUpdateTitle}
                onHide={() => setShowModalUpdateTitle(false)} animation={false}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form noValidate onSubmit={handleSubmitUpdateTitle}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Rename the group
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Room title</Form.Label>
                            <Form.Control required
                                          type="text"
                                          placeholder="input room titles"
                                            defaultValue={room.title}
                                          name="title" />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalUpdateTitle(false)}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <LeaveGroup/>
            <div className="profile">
                <Picture group={room.isGroup} picture={room.isGroup ? room.picture : other.picture} user={other} />
            </div>
            <div className="name">
                {title.substr(0, 20)}{title.length > 20 && '...'}
                {user.id === room.owner && (
                    <span className="action-update-title" onClick={() => setShowModalUpdateTitle(true)}>
                        <FiEdit/>
                    </span>
                )}
            </div>
            <div className="title" hidden>{other.tagLine || 'No Tag Line'}</div>
            <button className="details-button uk-margin-remove-bottom uk-button uk-button-secondary" onClick={() => setViewMembers(!viewMembers)}>View {viewMembers ? 'Images' : 'Members'}</button>

            <div className={`members ${viewMembers ? '' : 'display_none'}`} >
                {/*{room.isGroup && room.owner === user.id && (<AddToGroup room={room}/>)}*/}
                {room.isGroup && (<AddToGroup room={room}/>)}

                {members}
            </div>

            <div className="images" ref={scrollContainer} onScroll={onScroll} hidden={viewMembers}>
                {open && (
                    <Lightbox
                        medium={`${Config.url || ''}/api/images/${open.content}/1024/${brand}`}
                        large={`${Config.url || ''}/api/images/${open.content}/2048/${brand}`}
                        alt="Lightbox"
                        hideDownload={true}
                        onClose={() => setOpen(null)}
                    />
                )}
                {images}
                <Notice/>
            </div>
        </div>
    );
};

export default Room;
