import React, {useEffect, useState} from 'react';
import "./Room.sass";
import {useGlobal} from "reactn";
import {FiPhone, FiMoreHorizontal} from 'react-icons/fi';
import Picture from "../../../components/Picture";
import moment from "moment";
import {useHistory, useLocation} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import getMeetingRoom from "../../../actions/getMeetingRoom";
import {useDispatch, useSelector} from "react-redux";
import postCall from "../../../actions/postCall";
import Actions from "../../../constants/Actions";
import removeRoom from "../../../actions/removeRoom";
import getRooms from "../../../actions/getRooms";
import typing from "../../../actions/typing";
import MasterConstant from "../../../constants/MasterConstant";
import getNotificationUser from "../../../actions/getNotificationUser";
import updateChatNotification from "../../../actions/updateChatNotification";

const Room = ({room}) => {
    const roomsWithNewMessages = useSelector(state => state.messages.roomsWithNewMessages);
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const currentRoom = useSelector(state => state.io.room);
    const [hover, setHover] = useState(false);
    const [roomActive, setRoomActive] = useState(false);
    const user = useGlobal('user')[0];
    const setAudio = useGlobal('audio')[1];
    const setVideo = useGlobal('video')[1];
    const setCallDirection = useGlobal('callDirection')[1];
    const setMeeting = useGlobal('meeting')[1];

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    // let newMess = false;
    const [newMess, setNewMess] = useState(false);
    const [activeRoomRef, setActiveRoomRef] = useState();

    let defaultActive = false;

    useEffect(() => {
        let messagesReceived = room.messagesReceived.filter(r => user.id === r.people);
        if(messagesReceived.length > 0){
            setNewMess(messagesReceived[0].total);
        }

        if(currentRoom && room){
            if(currentRoom._id == room._id){
                setRoomActive(true);
            }
        }
    }, [room, currentRoom, roomActive])
    let other = {};
    room.people.forEach(person => {
        if (user.id !== person._id) other = person;
    });

    if (!other.firstName) {
        other = { ...other, firstName: 'Deleted', lastName: 'User' };
    }

    const title = (room.isGroup ? room.title : `${other.firstName} ${other.lastName}`).substr(0, 22);

    let lastMessage = room.lastMessage;
    let text = '';

    if (!lastMessage && room.isGroup) text = 'New group created.';
    if (!lastMessage && !room.isGroup) text = `No messages with ${other.firstName} yet.`;

    if (!lastMessage) lastMessage = {};

    if (lastMessage.author === user.id && !room.isGroup) text += 'You: ';

    switch (lastMessage.type) {
        case 'file':
            text += 'Sent a file.';
            break;
        case 'image':
            text += 'Sent a picture.';
            break;
        default:
            let finalContent = lastMessage.content;
            if(typeof finalContent != 'undefined'){
                let tagFullName, tagUserId;
                let tagUser = finalContent.match(/@((?!@).)*-((?!@).)*@/gm);
                if(tagUser){
                    tagUser.map(item => {

                        let tag = item.replace(/@/g, '');
                        let objTag = tag.split('-');
                        tagFullName = objTag[0];
                        tagUserId = objTag[1];
                        finalContent = finalContent.replace(item, '@' + tagFullName + ' ');
                    });
                }
            }


            text += (finalContent || '');
    }

    const date = lastMessage ? moment(room.lastUpdate).format('MMM D') : '';
    const time = lastMessage ? moment(room.lastUpdate).format('h:mm A') : '';

    const popup = (content, type) => {
        addToast(content, {
            appearance: type,
            autoDismiss: true,
        })
    };

    const remove = async () => {
        if (window.confirm('Are you sure you want to remove this room? All associated messages will be deleted, both for you and other members.')) {
            try {
                await removeRoom(room._id);
                popup('Room has been deleted.', 'success');
                getRooms().then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
                if (currentRoom && room._id === currentRoom._id) history.replace('/');
            } catch (e) {
                popup('Error while removing room. Please retry!', 'error');
            }
        }
    };

    const width = window.innerWidth;
    const isMobile = width < 700;

    const warningToast = content => {
        addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
        })
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    const call = async (callee, isVideo) => {
        if (onlineUsers.filter(u => u.id === other._id).length === 0 && !room.isGroup)
            return warningToast("Can't call user because user is offline");
        await setAudio(true);
        await setVideo(isVideo);
        await setCallDirection('outgoing');
        dispatch({type: Actions.RTC_SET_COUNTERPART, counterpart: callee});
        try {
            const res = await getMeetingRoom({startedAsCall: true, caller: user.id, callee: other._id, callToGroup: room.isGroup, group: room._id});
            await setMeeting(res.data);
            history.replace('/meeting/' + res.data._id);
            await postCall({roomID: room._id, meetingID: res.data._id});
        }
        catch (e) {
            errorToast('Server error. Unable to initiate call.');
        }
    };

    const getStatus = () => {
        if (room.isGroup) return null;
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
        return null;
    };

    const handleOnclickRoom = async () => {



        let elmRoom = document.getElementsByClassName('room');
        for await (let item of elmRoom) {
            item.classList.remove('room-active')
        }

        //add class active
        activeRoomRef.classList.add('room-active')

        window.parent.postMessage({
            'func': 'reloadCountNoti',
        }, "*");

        const target = `/room/${room._id}`;
        if (location.pathname !== target) await history.replace(target);
        await updateChatNotification(room._id).then().catch(err => console.log(err));
        await getRooms().then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
        await getNotificationUser().then((res) => {
            dispatch({type: Actions.SET_USER_NOTIFICATION, notificationsUser: res.data})

        }).catch(err => console.log(err));

    }

    return (
        <div
            className={`room ${roomActive && "room-active"} uk-flex  `}
            onMouseOver={!isMobile ? () => setHover(true) : undefined}
            onMouseOut={!isMobile ? () => setHover(false) : undefined}
            ref={c => (setActiveRoomRef(c))}
            onClick={handleOnclickRoom}>
            <div className="uk-flex uk-flex-middle">
                <div className="profile">
                    <Picture user={other} group={room.isGroup} picture={room.picture} title={room.title} />
                </div>
                {getStatus() && <div className={`dot ${getStatus()}`} />}
            </div>
            <div className="text">
                <div className={`title ${newMess > 0 ? ' highlight' : ''}`}>
                    {title.substr(0, 20)}{title.length > 20 && '...'}
                </div>
                <div className={`message ${newMess > 0 ? ' highlight' : ''}`}>
                    {text.substr(0, 26)}{text.length > 26 && '...'}
                </div>
            </div>
            <div className="controls" hidden={hover}>
                <div className="date">
                    <span className="room_date">{MasterConstant.convertDate(room.lastUpdate)}</span>
                    <br/>
                    <span className={newMess > 0 ? 'room_noti' : ''}>{newMess > 0 ? (newMess > 99 ? '99+' : newMess ): ' '}</span></div>
            </div>
            {/*<div className="controls" hidden={!hover}>*/}
            {/*    /!*<div className="button" onClick={() => call(other, false)}>*!/*/}
            {/*    /!*    <FiPhone/>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    <div className="uk-inline">*/}
            {/*        <div className="button" type="button">*/}
            {/*            <FiMoreHorizontal/>*/}
            {/*        </div>*/}
            {/*        <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar">*/}
            {/*            <div className="link" onClick={remove}>Remove</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default Room;
