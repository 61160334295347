import axios from "axios";
import Config from "../config";

const getFavorites = (keyword = null) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/favorites/list",
        data: {keyword}
    });
};

export default getFavorites;
