import React, {useEffect} from 'react';
import {useGlobal} from "reactn";
import "./NavBar.sass";
import {FiCalendar, FiMessageCircle, FiStar, FiUsers, FiSearch, FiBook} from 'react-icons/fi';
import {IoIosNotificationsOutline} from "react-icons/all";
import {useSelector} from "react-redux";

const NavBar = () => {
    const [nav, setNav] = useGlobal('nav');
    const notificationsUser = useSelector(state => state.io.notificationsUser);

    return (
        <div className="nav-bar uk-flex">
            <div className={`item${nav === 'rooms' ? ' active' : ''}`} onClick={() => setNav('rooms')}>
                <div className="icon">
                    <FiMessageCircle/>
                    {notificationsUser && (
                        notificationsUser.rooms > 0 && (
                                <span className="nav-noti nav-chat-noti">{notificationsUser.rooms > 99 ? '99+' : notificationsUser.rooms}</span>
                        )
                    )}

                </div>
                <div className="text">Chats</div>
            </div>
            <div className={`item${nav === 'search' ? ' active' : ''}`} onClick={() => setNav('search')}>
                <div className="icon">
                    <FiBook/>
                </div>
                <div className="text">Contacts</div>
            </div>
            <div className={`item${nav === 'favorites' ? ' active' : ''}`} onClick={() => setNav('favorites')}>
                <div className="icon">
                    <FiStar/>
                </div>
                <div className="text">Favorites</div>
            </div>
            <div className={`item${nav === 'notification' ? ' active' : ''}`} onClick={() => {
                setNav('notification');
            }}>
                <div className="icon">
                    <IoIosNotificationsOutline/>
                    {notificationsUser && (
                        notificationsUser.noti > 0 && (
                            <span className="nav-noti">{notificationsUser.noti > 99 ? '99+' : notificationsUser.noti}</span>
                        )
                    )}
                </div>
                <div className="text">Notifications</div>
            </div>
            {/*<div className={`item${nav === 'meetings' ? ' active' : ''}`} onClick={() => {*/}
            {/*    setNav('meetings');*/}
            {/*}}>*/}
            {/*    <div className="icon">*/}
            {/*        <FiUsers/>*/}
            {/*    </div>*/}
            {/*    <div className="text">Meetings</div>*/}
            {/*</div>*/}
        </div>
    );
}

export default NavBar;
